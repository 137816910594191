export const PermissionsActionTypes = {
    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',

    GET_PERMISSIONS: 'GET_PERMISSIONS',
    GET_PERMISSIONS_LOADING: 'GET_PERMISSIONS_LOADING',
    GET_PERMISSIONS_SUCCESS: 'GET_PERMISSIONS_SUCCESS',
    GET_PERMISSIONS_ERROR: 'GET_PERMISSIONS_ERROR',
    GET_PERMISSIONS_RESET: 'GET_PERMISSIONS_RESET',

    DELETE_PERMISSIONS: 'DELETE_PERMISSIONS',
    DELETE_PERMISSIONS_LOADING: 'DELETE_PERMISSIONS_LOADING',
    DELETE_PERMISSIONS_SUCCESS: 'DELETE_PERMISSIONS_SUCCESS',
    DELETE_PERMISSIONS_ERROR: 'DELETE_PERMISSIONS_ERROR',
    DELETE_PERMISSIONS_RESET: 'DELETE_PERMISSIONS_RESET',

    UPDATE_PERMISSIONS: 'UPDATE_PERMISSIONS',
    UPDATE_PERMISSIONS_LOADING: 'UPDATE_PERMISSIONS_LOADING',
    UPDATE_PERMISSIONS_SUCCESS: 'UPDATE_PERMISSIONS_SUCCESS',
    UPDATE_PERMISSIONS_ERROR: 'UPDATE_PERMISSIONS_ERROR',
    UPDATE_PERMISSIONS_RESET: 'UPDATE_PERMISSIONS_RESET',

    CREATE_PERMISSIONS: 'CREATE_PERMISSIONS',
    CREATE_PERMISSIONS_LOADING: 'CREATE_PERMISSIONS_LOADING',
    CREATE_PERMISSIONS_SUCCESS: 'CREATE_PERMISSIONS_SUCCESS',
    CREATE_PERMISSIONS_ERROR: 'CREATE_PERMISSIONS_ERROR',
    CREATE_PERMISSIONS_RESET: 'CREATE_PERMISSIONS_RESET',

};