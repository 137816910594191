export const RoleManagementActionTypes = {
    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',

    CREATE_USER_ROLE: 'CREATE_USER_ROLE',
    CREATE_USER_ROLE_LOADING: 'CREATE_USER_ROLE_LOADING',
    CREATE_USER_ROLE_SUCCESS: 'CREATE_USER_ROLE_SUCCESS',
    CREATE_USER_ROLE_ERROR: 'CREATE_USER_ROLE_ERROR',
    CREATE_USER_ROLE_RESET: 'CREATE_USER_ROLE_RESET',

    UPDATE_USER_ROLE: 'UPDATE_USER_ROLE',
    UPDATE_USER_ROLE_LOADING: 'UPDATE_USER_ROLE_LOADING',
    UPDATE_USER_ROLE_SUCCESS: 'UPDATE_USER_ROLE_SUCCESS',
    UPDATE_USER_ROLE_ERROR: 'UPDATE_USER_ROLE_ERROR',
    UPDATE_USER_ROLE_RESET: 'UPDATE_USER_ROLE_RESET',

    GET_USER_ROLE: 'GET_USER_ROLE',
    GET_USER_ROLE_LOADING: 'GET_USER_ROLE_LOADING',
    GET_USER_ROLE_SUCCESS: 'GET_USER_ROLE_SUCCESS',
    GET_USER_ROLE_ERROR: 'GET_USER_ROLE_ERROR',
    GET_USER_ROLE_RESET: 'GET_USER_ROLE_RESET',

}