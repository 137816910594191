export const SettingsActionTypes = {
    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',

    CREATE_MASTERS_DATA: 'CREATE_MASTERS_DATA',
    CREATE_MASTERS_DATA_LOADING: 'CREATE_MASTERS_DATA_LOADING',
    CREATE_MASTERS_DATA_SUCCESS: 'CREATE_MASTERS_DATA_SUCCESS',
    CREATE_MASTERS_DATA_ERROR: 'CREATE_MASTERS_DATA_ERROR',
    CREATE_MASTERS_DATA_RESET: 'CREATE_MASTERS_DATA_RESET',

    CREATE_NEW_CONTRACT: 'CREATE_NEW_CONTRACT',
    CREATE_NEW_CONTRACT_LOADING: 'CREATE_NEW_CONTRACT_LOADING',
    CREATE_NEW_CONTRACT_SUCCESS: 'CREATE_NEW_CONTRACT_SUCCESS',
    CREATE_NEW_CONTRACT_ERROR: 'CREATE_NEW_CONTRACT_ERROR',
    CREATE_NEW_CONTRACT_RESET: 'CREATE_NEW_CONTRACT_RESET',

    UPDATE_CONTRACT: 'UPDATE_CONTRACT',
    UPDATE_CONTRACT_LOADING: 'UPDATE_CONTRACT_LOADING',
    UPDATE_CONTRACT_SUCCESS: 'UPDATE_CONTRACT_SUCCESS',
    UPDATE_CONTRACT_ERROR: 'UPDATE_CONTRACT_ERROR',
    UPDATE_CONTRACT_RESET: 'UPDATE_CONTRACT_RESET',

    GET_NEW_CONTRACT: 'GET_NEW_CONTRACT',
    GET_NEW_CONTRACT_LOADING: 'GET_NEW_CONTRACT_LOADING',
    GET_NEW_CONTRACT_SUCCESS: 'GET_NEW_CONTRACT_SUCCESS',
    GET_NEW_CONTRACT_ERROR: 'GET_NEW_CONTRACT_ERROR',
    GET_NEW_CONTRACT_RESET: 'GET_NEW_CONTRACT_RESET',

    GET_MASTERS_DATA: 'GET_MASTERS_DATA',
    GET_MASTERS_DATA_LOADING: 'GET_MASTERS_DATA_LOADING',
    GET_MASTERS_DATA_SUCCESS: 'GET_MASTERS_DATA_SUCCESS',
    GET_MASTERS_DATA_ERROR: 'GET_MASTERS_DATA_ERROR',
    GET_MASTERS_DATA_RESET: 'GET_MASTERS_DATA_RESET',

    GET_TENURE: 'GET_TENURE',
    GET_TENURE_LOADING: 'GET_TENURE_LOADING',
    GET_TENURE_SUCCESS: 'GET_TENURE_SUCCESS',
    GET_TENURE_ERROR: 'GET_TENURE_ERROR',
    GET_TENURE_RESET: 'GET_TENURE_RESET',

    UPDATE_MASTERS_DATA: 'UPDATE_MASTERS_DATA',
    UPDATE_MASTERS_DATA_LOADING: 'UPDATE_MASTERS_DATA_LOADING',
    UPDATE_MASTERS_DATA_SUCCESS: 'UPDATE_MASTERS_DATA_SUCCESS',
    UPDATE_MASTERS_DATA_ERROR: 'UPDATE_MASTERS_DATA_ERROR',
    UPDATE_MASTERS_DATA_RESET: 'UPDATE_MASTERS_DATA_RESET',

}