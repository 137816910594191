export const EmailChangeActionTypres = {
    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',

    GENERATE_OTP: 'GENERATE_OTP',
    GENERATE_OTP_LOADING: 'GENERATE_OTP_LOADING',
    GENERATE_OTP_SUCCESS: 'GENERATE_OTP_SUCCESS',
    GENERATE_OTP_ERROR: 'GENERATE_OTP_ERROR',
    GENERATE_OTP_RESET: 'GENERATE_OTP_RESET',

    VERIFY_OTP: 'VERIFY_OTP',
    VERIFY_OTP_LOADING: 'VERIFY_OTP_LOADING',
    VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
    VERIFY_OTP_ERROR: 'VERIFY_OTP_ERROR',
    VERIFY_OTP_RESET: 'VERIFY_OTP_RESET',

    NEW_EMAIL: 'NEW_EMAIL',
    NEW_EMAIL_LOADING: 'NEW_EMAIL_LOADING',
    NEW_EMAIL_SUCCESS: 'NEW_EMAIL_SUCCESS',
    NEW_EMAIL_ERROR: 'NEW_EMAIL_ERROR',
    NEW_EMAIL_RESET: 'NEW_EMAIL_RESET',

}