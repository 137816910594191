export const SubscriptionsActionTypes = {
    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',

    GET_SUBSCRIPTIONS: 'GET_SUBSCRIPTIONS',
    GET_SUBSCRIPTIONS_LOADING: 'GET_SUBSCRIPTIONS_LOADING',
    GET_SUBSCRIPTIONS_SUCCESS: 'GET_SUBSCRIPTIONS_SUCCESS',
    GET_SUBSCRIPTIONS_ERROR: 'GET_SUBSCRIPTIONS_ERROR',
    GET_SUBSCRIPTIONS_RESET: 'GET_SUBSCRIPTIONS_RESET',

    CREATE_SUBSCRIPTIONS: 'CREATE_SUBSCRIPTIONS',
    CREATE_SUBSCRIPTIONS_LOADING: 'CREATE_SUBSCRIPTIONS_LOADING',
    CREATE_SUBSCRIPTIONS_SUCCESS: 'CREATE_SUBSCRIPTIONS_SUCCESS',
    CREATE_SUBSCRIPTIONS_ERROR: 'CREATE_SUBSCRIPTIONS_ERROR',
    CREATE_SUBSCRIPTIONS_RESET: 'CREATE_SUBSCRIPTIONS_RESET',

    UPDATE_SUBSCRIPTIONS: 'UPDATE_SUBSCRIPTIONS',
    UPDATE_SUBSCRIPTIONS_LOADING: 'UPDATE_SUBSCRIPTIONS_LOADING',
    UPDATE_SUBSCRIPTIONS_SUCCESS: 'UPDATE_SUBSCRIPTIONS_SUCCESS',
    UPDATE_SUBSCRIPTIONS_ERROR: 'UPDATE_SUBSCRIPTIONS_ERROR',
    UPDATE_SUBSCRIPTIONS_RESET: 'UPDATE_SUBSCRIPTIONS_RESET',

}