export const ClinicsActionTypes = {
    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',

    CREATE_CLINIC: 'CREATE_CLINIC',
    CREATE_CLINIC_LOADING: 'CREATE_CLINIC_LOADING',
    CREATE_CLINIC_SUCCESS: 'CREATE_CLINIC_SUCCESS',
    CREATE_CLINIC_ERROR: 'CREATE_CLINIC_ERROR',
    CREATE_CLINIC_RESET: 'CREATE_CLINIC_RESET',

    GET_CLINIC: 'GET_CLINIC',
    GET_CLINIC_LOADING: 'GET_CLINIC_LOADING',
    GET_CLINIC_SUCCESS: 'GET_CLINIC_SUCCESS',
    GET_CLINIC_ERROR: 'GET_CLINIC_ERROR',
    GET_CLINIC_RESET: 'GET_CLINIC_RESET',

    GET_CLINIC_BY_PARENT: 'GET_CLINIC_BY_PARENT',
    GET_CLINIC_BY_PARENT_LOADING: 'GET_CLINIC_BY_PARENT_LOADING',
    GET_CLINIC_BY_PARENT_SUCCESS: 'GET_CLINIC_BY_PARENT_SUCCESS',
    GET_CLINIC_BY_PARENT_ERROR: 'GET_CLINIC_BY_PARENT_ERROR',
    GET_CLINIC_BY_PARENT_RESET: 'GET_CLINIC_BY_PARENT_RESET',

    GET_STATES: 'GET_STATES',
    GET_STATES_LOADING: 'GET_STATES_LOADING',
    GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
    GET_STATES_ERROR: 'GET_STATES_ERROR',
    GET_STATES_RESET: 'GET_STATES_RESET',

    GET_CITIES: 'GET_CITIES',
    GET_CITIES_LOADING: 'GET_CITIES_LOADING',
    GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
    GET_CITIES_ERROR: 'GET_CITIES_ERROR',
    GET_CITIES_RESET: 'GET_CITIES_RESET',

    GET_CITIES2: 'GET_CITIES2',
    GET_CITIES2_LOADING: 'GET_CITIES2_LOADING',
    GET_CITIES2_SUCCESS: 'GET_CITIES2_SUCCESS',
    GET_CITIES2_ERROR: 'GET_CITIES2_ERROR',
    GET_CITIES2_RESET: 'GET_CITIES2_RESET',

    UPDATE_CLINIC: 'UPDATE_CLINIC',
    UPDATE_CLINIC_LOADING: 'UPDATE_CLINIC_LOADING',
    UPDATE_CLINIC_SUCCESS: 'UPDATE_CLINIC_SUCCESS',
    UPDATE_CLINIC_ERROR: 'UPDATE_CLINIC_ERROR',
    UPDATE_CLINIC_RESET: 'UPDATE_CLINIC_RESET',

    GET_CLINIC_BY_ID: 'GET_CLINIC_BY_ID',
    GET_CLINIC_BY_ID_LOADING: 'GET_CLINIC_BY_ID_LOADING',
    GET_CLINIC_BY_ID_SUCCESS: 'GET_CLINIC_BY_ID_SUCCESS',
    GET_CLINIC_BY_ID_ERROR: 'GET_CLINIC_BY_ID_ERROR',
    GET_CLINIC_BY_ID_RESET: 'GET_CLINIC_BY_ID_RESET',

    CREATE_SUBSCRIPTION: 'CREATE_SUBSCRIPTION',
    CREATE_SUBSCRIPTION_LOADING: 'CREATE_SUBSCRIPTION_LOADING',
    CREATE_SUBSCRIPTION_SUCCESS: 'CREATE_SUBSCRIPTION_SUCCESS',
    CREATE_SUBSCRIPTION_ERROR: 'CREATE_SUBSCRIPTION_ERROR',
    CREATE_SUBSCRIPTION_RESET: 'CREATE_SUBSCRIPTION_RESET',

    DELETE_CLINIC: 'DELETE_CLINIC',
    DELETE_CLINIC_LOADING: 'DELETE_CLINIC_LOADING',
    DELETE_CLINIC_SUCCESS: 'DELETE_CLINIC_SUCCESS',
    DELETE_CLINIC_ERROR: 'DELETE_CLINIC_ERROR',
    DELETE_CLINIC_RESET: 'DELETE_CLINIC_RESET',

    GET_CONTRACT: 'GET_CONTRACT',
    GET_CONTRACT_LOADING: 'GET_CONTRACT_LOADING',
    GET_CONTRACT_SUCCESS: 'GET_CONTRACT_SUCCESS',
    GET_CONTRACT_ERROR: 'GET_CONTRACT_ERROR',
    GET_CONTRACT_RESET: 'GET_CONTRACT_RESET',

    GENERATE_CONTRACT: 'GENERATE_CONTRACT',
    GENERATE_CONTRACT_LOADING: 'GENERATE_CONTRACT_LOADING',
    GENERATE_CONTRACT_SUCCESS: 'GENERATE_CONTRACT_SUCCESS',
    GENERATE_CONTRACT_ERROR: 'GENERATE_CONTRACT_ERROR',
    GENERATE_CONTRACT_RESET: 'GENERATE_CONTRACT_RESET',
}