export const RolesActionTypes = {
    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',


    CREATE_ROLES: 'CREATE_ROLES',
    CREATE_ROLES_LOADING: 'CREATE_ROLES_LOADING',
    CREATE_ROLES_SUCCESS: 'CREATE_ROLES_SUCCESS',
    CREATE_ROLES_ERROR: 'CREATE_ROLES_ERROR',
    CREATE_ROLES_RESET: 'CREATE_ROLES_RESET',

    GET_ROLES: 'GET_ROLES',
    GET_ROLES_LOADING: 'GET_ROLES_LOADING',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_ERROR: 'GET_ROLES_ERROR',
    GET_ROLES_RESET: 'GET_ROLES_RESET',

    UPDATE_ROLES: 'UPDATE_ROLES',
    UPDATE_ROLES_LOADING: 'UPDATE_ROLES_LOADING',
    UPDATE_ROLES_SUCCESS: 'UPDATE_ROLES_SUCCESS',
    UPDATE_ROLES_ERROR: 'UPDATE_ROLES_ERROR',
    UPDATE_ROLES_RESET: 'UPDATE_ROLES_RESET',
}