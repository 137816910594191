import { APICore } from '../../helpers/api/apiCore';
import * as URL from '../../helpers/api/apiEndpoints';

const api = new APICore();

export function getAnalyticsApi(params: any): any {
    const { data } = params;
    return api.get(URL.getAnalytics, data);
}

